import { Component } from '@angular/core';
import RELEASE from '@mlc/release';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  year = new Date().getFullYear();
  build = `v${RELEASE.version}-${RELEASE.build}`;
}

